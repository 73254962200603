
import asyncFormSubmitter from '../../../assets/scripts/interfaces/async-form-submitter'
import Component from '../../../assets/scripts/modules/component'
import fireCustomEvent from '../../../assets/scripts/utilities/fire-custom-event'
import { hydratorRegisterComponent } from '../../../assets/scripts/utilities/hydrator'

function resetForm (formElement, initialFormData) {
  for (const fieldElement of Array.from(formElement.querySelectorAll('[name]'))) {
    fieldElement.value = initialFormData.get(fieldElement.name)
    fieldElement.dispatchEvent(new Event('change'))
    fieldElement.dispatchEvent(new Event('input'))
  }
}

function fillQueryParamters () {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())
  for (const key in params) {
    if (document.querySelector(`input[id="${key}"]`)) {
      document.querySelector(`input[id="${key}"]`).value = params[key]
      document.querySelector(`input[id="${key}"]`).parentNode.parentNode.classList.add('text-field--has-value')
      document.querySelector(`input[id="${key}"]`).parentNode.parentNode.classList.add('text-field--has-changed')
    }
  }
}

class FormComponent extends Component {
  init () {
    this.submitButton = this.element.querySelector('button[type="submit"], input[type="submit"]')
    this.element.addEventListener('submit', this.preventMultipleSubmits.bind(this))
    const isInlineVariant = this.element.classList.contains('form--forminline')
    const asyncSubmit = this.element.dataset.async_submit === 'true'
    const element = this.element

    if (this.submitButton) {
      this.spinner = this.submitButton.querySelector('.button__icon--before')
    }

    if (isInlineVariant) {
      const cancelButton = this.element.querySelector('.form__reset')
      const initialFormData = new FormData(this.element)
      cancelButton.addEventListener('click', () => resetForm(this.element, initialFormData))

      // This code works as long as this variant is used only in forms with a single field
      const formActions = this.element.querySelector('.form__actions')
      const singleElement = this.element.querySelector('input, textarea, select')
      singleElement.addEventListener('focus', (event) => {
        setTimeout(function () {
          formActions.style.display = 'block'
          element.classList.add('expanded')
        }, 250)
      }, true)

      singleElement.addEventListener('blur', (event) => {
        setTimeout(function () {
          formActions.style.display = 'none'
          element.classList.remove('expanded')
        }, 250)
      }, true)
    }

    if (asyncSubmit) {
      const { submit } = asyncFormSubmitter(this.element.action, window.CNV_APP.csrfToken)

      this.element.addEventListener('submit', async (event) => {
        event.preventDefault()
        const response = await submit(new FormData(this.element))
        if (response.errors) {
          for (const [fieldName, errors] of response.errors) {
            fireCustomEvent('form-error', { formElement: this.element, fieldName, errors })
          }
        }
      })
    }

    fillQueryParamters()
  }

  preventMultipleSubmits () {
    // LMO: Delayed this functionality because it causes the submit value to not be added to the POST request in case
    //      of it being set (!!!!)
    //      This technically allows the submit button to be executed multiple times still, but that is the not issue
    //      being solved here. The issue being solved is the general user not accidentally clicking multiple times.
    setTimeout(() => {
      if (this.submitButton) {
        this.submitButton.disabled = true
      }

      if (this.spinner) {
        this.spinner.style.display = 'block'
      }

      setTimeout(() => {
        if (this.submitButton) {
          // We prevented the user rapidly clicking again. Maybe we can reuse the submit
          // button for different causes. We won't disable the spinner here though and
          // leave that for custom code
          this.submitButton.disabled = false
        }
      }, 1000)
    })
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.form').forEach(element => {
  element.instance = element.instance || new FormComponent(element)
}))

hydratorRegisterComponent('.form', FormComponent)
