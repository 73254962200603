
import Component from '../../../assets/scripts/modules/component'

let index = 1
const expandableItems = {}

export default class MenuPanelComponent extends Component {
  init () {
    this.parentPanel = this.element.closest('.menu-panel')
    this.element.style.zIndex = index++
    this.returnLinkParent = Array.from(this.element.children).find(el => el.classList.contains('menu-panel__return'))

    if (this.parentPanel && this.returnLinkParent) {
      this.returnLinkParent.querySelector('a').addEventListener('click', () => this.goToParent())
    }

    // Show more items - Disabled for now...
    // if (this.element.classList.contains('menu-panel--advice')) {
    //   const linkGroups = document.querySelectorAll('.menu-panel--advice .menu-panel__link-group')
    //   Array.from(linkGroups).forEach((linkGroup, idx) => this.initExpandable(linkGroup, idx))
    // }
  }

  initExpandable (linkGroup, idx) {
    expandableItems[idx] = Array.from(linkGroup.querySelectorAll('.menu-link--regular-link'))
    const loadMore = linkGroup.querySelector('.button--loadmore')
    const maxItems = 7
    const hiddenClass = 'menu-link--regular-link-hide'

    // When more than max items, hide all following items
    expandableItems[idx].forEach(function (item, index) {
      if (index > maxItems - 1) {
        item.classList.add(hiddenClass)
      }
    })

    // Conditionally hide load more btn
    if (expandableItems[idx].length <= maxItems) {
      loadMore.style.display = 'none'
      return
    }

    // Add listener to btn
    loadMore.addEventListener('click', () => {
      for (const item of expandableItems[idx]) {
        item.classList.remove(hiddenClass)
      }

      // Remove load more button if no more items are hidden
      if (expandableItems[idx].filter((i) => i.classList.contains(hiddenClass)).length === 0) {
        loadMore.style.display = 'none'
      }
    })
  }

  goToParent () {
    this.element.classList.add('menu-panel--inactive')
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.menu-panel').forEach(element => {
  element.instance = element.instance || new MenuPanelComponent(element)
}))
