import { DropdownMenu } from '../../../assets/scripts/components/dropdown-menu'
import Component from '../../../assets/scripts/modules/component'
import { clickedOutsideMultiple } from '../../../assets/scripts/utilities/outside-click'

export default class MenuBarComponent extends Component {
  init () {
    const profileButtonElement = this.element.querySelector('[data-role=profile-button]')
    const profilePanelElement = this.element.querySelector('[data-role=profile-panel]')

    const userAvatarElements = Array.from(this.element.querySelectorAll('[data-role=user-avatar]'))
    const userNameElements = Array.from(this.element.querySelectorAll('[data-role=user-fullname]'))
    const loginUrlElements = Array.from(this.element.querySelectorAll('[data-role=login-url]'))
    const logoutUrlElements = Array.from(this.element.querySelectorAll('[data-role=logout-url]'))
    const profileUrlElements = Array.from(this.element.querySelectorAll('[data-role=user-profile-url]'))
    const swapUrlElements = Array.from(this.element.querySelectorAll('[data-role=user-swap-url]'))

    this.element.classList.add(window.CNV_APP?.isLoggedIn ? 'menu-bar--logged-in' : 'menu-bar--logged-out')

    // Assign CNV_APP vars
    userAvatarElements.forEach((el) => (el.src = window.CNV_APP?.userAvatar))
    userNameElements.forEach((el) => (el.innerText = window.CNV_APP?.userFullName))
    loginUrlElements.forEach((el) => (el.href = window.CNV_APP?.loginUrl))
    logoutUrlElements.forEach((el) => (el.href = window.CNV_APP?.logoutUrl))
    profileUrlElements.forEach((el) => (el.href = window.CNV_APP?.myProfileUrl))
    swapUrlElements.forEach((el) => (el.href = window.CNV_APP?.swapUrl))

    const raffleKey = this.element.dataset.raffleKey
    if (raffleKey) { this.initRaffle(raffleKey) }

    this.mainMenu = this.element.querySelector('.menu-bar__main-menu')

    // Desktop menu listeners
    if (profileButtonElement && profilePanelElement) {
      const profilePanel = DropdownMenu(profilePanelElement)

      profileButtonElement.addEventListener('click', (event) => {
        event.preventDefault()

        profileButtonElement.classList.toggle('menu-panel-profile--filled')
        profilePanel.isOpen() ? profilePanel.close() : profilePanel.open()
      })

      const box = document.querySelector('.menu-bar__profile-button')

      document.addEventListener('click', (e) => {
        if (clickedOutsideMultiple(e, [profileButtonElement, profilePanelElement])) {
          box.classList.remove('menu-panel-profile--filled')
          profilePanel.close()
        }
      })

      window.addEventListener('close-menu-panels', () => {
        profilePanel.close()
        this.toggleMenu(false)
      })
    }

    // Mobile menu listeners
    Array.from(this.element.querySelectorAll('.menu-bar__menu-opener, .menu-bar__menu-closer')).forEach((el) => {
      el.addEventListener('click', () => this.toggleMenu())
    })

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'login',
      loginstatus: window.CNV_APP?.isLoggedIn ? 'ingelogd' : 'uitgelogd',
      lidstatus: window.CNV_APP?.userIsMember ? 'lid' : 'geen lid'
    })

    const activeCardOverlay = document.getElementsByClassName('menu-panel-login-dropdown__close')
    if (activeCardOverlay.length > 0) {
      const cardOverlays = document.querySelectorAll('.menu-panel-login-dropdown')

      for (const cardOverlay of cardOverlays) {
        cardOverlay.addEventListener('click', function handleClick () {
          cardOverlay.classList.add('is-collapsed')
        })
      }
    }
  }

  initRaffle (code) {
    const raffleButton = document.getElementById('raffleSeachButton')

    if (!raffleButton) {
      return
    }

    raffleButton.addEventListener('click', activeRaffleSearch)

    function activeRaffleSearch () {
      window.raffleApi.open(code)
    }

    this.element.classList.add('menu-bar--raffle-enabled')
  }

  // Mobile menu toggle
  toggleMenu (makeOpen = null) {
    // eslint-disable-next-line smells/no-this-assign
    const { element, mainMenu } = this
    const isOpen = makeOpen !== null ? !makeOpen : !mainMenu.classList.contains('menu-panel--inactive')

    if (isOpen) {
      Array.from(this.element.querySelectorAll('.menu-panel')).forEach((el) => {
        el.classList.add('menu-panel--inactive')
        // el.classList.remove('menu-panel--slide-down')
      })
      element.classList.remove('menu-bar--active')
      // mainMenu.classList.add('menu-panel--slide-up')
      document.body.classList.remove('no-scroll')
    } else {
      mainMenu.classList.remove('menu-panel--inactive')
      // mainMenu.classList.remove('menu-panel--slide-up')
      // mainMenu.classList.add('menu-panel--slide-down')
      element.classList.add('menu-bar--active')
      document.body.classList.add('no-scroll')
    }
  }

  // // Idea to open the menu on alt-click and make the menu panels / links navigatable with arrows... Only if there is spare time
  // // Register Accessibility
  // registerAccessibility () {
  //   document.addEventListener('keydown', (ev) => {
  //     if (ev.key === 'Alt') {
  //       const mainMenuLinks = document.getElementsByClassName('menu-link menu-link--popup-menu')
  //       console.log('clicked alt key', mainMenuLinks)
  //       mainMenuLinks[0].children[0].click()
  //     }
  //   })
  // }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.menu-bar').forEach((element) => {
    element.instance = element.instance || new MenuBarComponent(element)
  })
)
