import Component from '../../../assets/scripts/modules/component'
import { hydratorRegisterComponent } from '../../../assets/scripts/utilities/hydrator'

export default class ChooseCaoComponent extends Component {
  init () {
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.choose-sector').forEach(element => {
  element.instance = element.instance || new ChooseCaoComponent(element)
}))
hydratorRegisterComponent('.choose-cao', ChooseCaoComponent)
