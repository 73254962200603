import Component from '../../../assets/scripts/modules/component'

export default class CtaBannerComponent extends Component {
  init () {
    const conversionBanner = document.querySelector('.section-divider')
    console.log(conversionBanner, this.element.classList.contains('cta-banner--fixed'))

    // Desktop banner hide/show logic
    if (this.element.classList.contains('cta-banner--fixed')) {
      if (conversionBanner) {
        console.log('Yes')
        new IntersectionObserver((entries, observer) => {
          entries.forEach(entry => {
            console.log(entry.isIntersecting ? 'hide!' : 'show!')
            entry.isIntersecting ? this.hide() : this.show()
          })
        }).observe(conversionBanner)
      }

      const observableElement = document.getElementById(this.element.dataset.elementObserve)
      if (observableElement) {
        const rootMargin = `-50% 0px -50% 0px`
        new IntersectionObserver((entries, observer) => {
          entries.forEach(entry => {
            entry.isIntersecting ? this.show() : this.hide()
          })
        }, { rootMargin }).observe(observableElement)
      }
    }

    // Hide mobile CTA banner if conversion banner was added to the page
    if (this.element.classList.contains('cta-banner--mobile') && conversionBanner) {
      this.element.style.display = 'none'
    }
  }

  hide () {
    this.element.classList.add('anim-hide')
    this.element.addEventListener('transitionend', () => {
      if (this.element.classList.contains('anim-hide')) {
        this.element.classList.add('cta-banner--hidden')
      }
    }, {
      once: true
    })
  }

  show () {
    this.element.classList.remove('cta-banner--hidden')
    // eslint-disable-next-line no-unused-expressions
    this.element.offsetHeight // force dom update
    this.element.classList.remove('anim-hide')
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.cta-banner').forEach(element => {
  element.instance = element.instance || new CtaBannerComponent(element)
}))
