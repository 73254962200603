
// import konamiCode from './utilities/konami-code.js'

// Import in reverse order from stylesheets
require('../../components/structures/cookie-bar/cookie-bar.js')
require('../../components/structures/footer-bar/footer-bar.js')
require('../../components/structures/form-membership/form-membership.js')
require('../../components/structures/menu-bar/menu-bar.js')
require('../../components/structures/menu-overlay/menu-overlay.js')
require('../../components/structures/sidebar/sidebar.js')
require('../../components/structures/skip-to-main/skip-to-main.js')
require('../../components/structures/ticker-tape/ticker-tape.js')
require('../../components/organisms/article-contact-carousel/article-contact-carousel.js')
require('../../components/organisms/article-cookies/article-cookies.js')
require('../../components/organisms/article-downloads/article-downloads.js')
require('../../components/organisms/article-nav-back-link/article-nav-back-link.js')
require('../../components/organisms/article-photo-carousel/article-photo-carousel.js')
require('../../components/organisms/cao-header/cao-header.js')
require('../../components/organisms/cao-overview/cao-overview.js')
require('../../components/organisms/cao-timeline/cao-timeline.js')
require('../../components/organisms/card-carousel/card-carousel.js')
require('../../components/organisms/card-grid-expandable/card-grid-expandable.js')
require('../../components/organisms/card-grid-featured/card-grid-featured.js')
require('../../components/organisms/content-carousel/content-carousel.js')
require('../../components/organisms/content-header/content-header.js')
require('../../components/organisms/content-with-sidebar/content-with-sidebar.js')
require('../../components/organisms/conversion-banner/conversion-banner.js')
require('../../components/organisms/cta-banner/cta-banner.js')
require('../../components/organisms/dossier-header/dossier-header.js')
require('../../components/organisms/dossier-testimonials/dossier-testimonials.js')
require('../../components/organisms/dossier-text/dossier-text.js')
require('../../components/organisms/filter-zipcode/filter-zipcode.js')
require('../../components/organisms/group-detail/group-detail.js')
require('../../components/organisms/group-list/group-list.js')
require('../../components/organisms/invite-member/invite-member.js')
require('../../components/organisms/login-details/login-details.js')
require('../../components/organisms/modal/modal.js')
require('../../components/organisms/profile-work/components/work-card.js')
require('../../components/organisms/profile-work/profile-work.js')
require('../../components/organisms/settings-action/settings-action.js')
require('../../components/organisms/settings-group/settings-group.js')
require('../../components/organisms/tabbed-content/tabbed-content.js')
require('../../components/molecules/address-field/address-field.js')
require('../../components/molecules/autocomplete/autocomplete.js')
require('../../components/molecules/comment-section/comment-section.js')
require('../../components/molecules/comment-section/components/comment-section-comment.js')
require('../../components/molecules/comment-section/components/comment-section-like-button.js')
require('../../components/molecules/comment-section/components/comment-section-reply-button.js')
require('../../components/molecules/copy-link/copy-link.js')
require('../../components/molecules/form/form.js')
require('../../components/molecules/idea-section/components/idea-section-idea.js')
require('../../components/molecules/idea-section/idea-section.js')
require('../../components/molecules/member-list/components/action-link.js')
require('../../components/molecules/member-list/member-list.js')
require('../../components/molecules/membership-calculation/membership-calculation.js')
require('../../components/molecules/menu-link/menu-link.js')
require('../../components/molecules/menu-panel/menu-panel.js')
require('../../components/molecules/notifications/notifications.js')
require('../../components/molecules/poll/poll.js')
require('../../components/molecules/profile-setting/profile-setting.js')
require('../../components/molecules/translation-menu/translation-menu.js')
require('../../components/molecules/usp-aside/usp-aside.js')
require('../../components/atoms/button-toggle/button-toggle.js')
require('../../components/atoms/choose-cao/choose-cao.js')
require('../../components/atoms/choose-sector/choose-sector.js')
require('../../components/atoms/disclosure/disclosure.js')
require('../../components/atoms/dropdown-field/dropdown-field-cluster.js')
require('../../components/atoms/dropdown-field/dropdown-field.js')
require('../../components/atoms/dropdown-menu/dropdown-menu.js')
require('../../components/atoms/file-field/file-field.js')
require('../../components/atoms/option-field/option-field.js')
require('../../components/atoms/picture/picture.js')
require('../../components/atoms/share-button/share-button.js')
require('../../components/atoms/text-field/text-field.js')
require('../../components/atoms/toggle/toggle.js')
require('../../components/atoms/video-embed/video-embed.js')
require('../../components/atoms/video/video.js')

// There are 4 load events:
// - 'init-immediate': (compiles when the script is loaded; blocks rendering)
// - 'init-load': (on DOMContentLoaded event; does not block rendering)
// - 'init-after-load': (on Load event, slightly after DOMContentLoaded)
// - 'init-delayed-load': (after Load event, with a slight delay, for iframes and such)
// Usually, the 'init-load' event will suffice.
window.dispatchEvent(new CustomEvent('init-immediate'))
window.addEventListener('DOMContentLoaded', () => window.dispatchEvent(new CustomEvent('init-load')))
window.addEventListener('load', () => window.dispatchEvent(new CustomEvent('init-after-load')))
window.addEventListener('load', () => window.setTimeout(() => window.dispatchEvent(new CustomEvent('init-delayed-load')), 1000))

// Focus stuff
document.addEventListener('keydown', () => document.documentElement.classList.add('key-pressed'))
document.addEventListener('mousedown', () => document.documentElement.classList.remove('key-pressed'))
document.addEventListener('touchstart', () => document.documentElement.classList.remove('key-pressed'))

// This gives you vh-like values, but without scrollbars and addressbar and such
// This allows usage of document height / width in CSS
// NOTE: Only turn this on when needed - very heavy when resizing
// NOTE: Resize observer only from Safari 13.1 onwards, so back to window.onresize.
// new ResizeObserver(() => {
// ...
// }).observe(document.documentElement)
//
// const onResizeHandler = () => {
//  window.requestAnimationFrame(() => {
//    document.documentElement.style.setProperty('--100vw', `${document.documentElement.clientWidth}px`)
//    document.documentElement.style.setProperty('--100vh', `${document.documentElement.clientHeight}px`)
//    document.documentElement.style.setProperty('--100dw', `${document.documentElement.offsetWidth}px`)
//    document.documentElement.style.setProperty('--100dh', `${document.documentElement.offsetHeight}px`)
//  })
// }
// window.addEventListener('resize', () => onResizeHandler())
// onResizeHandler()

// loading="lazy" polyfill (~3kB) - for non-Chrome
if (!('loading' in HTMLImageElement.prototype)) {
  require('loading-attribute-polyfill')
}

// Import smooth scroll (~35kB) - for Safari and Edge
if (!('scrollBehavior' in document.documentElement.style)) {
  const smoothscroll = require('smoothscroll-polyfill')
  smoothscroll.polyfill()
}

if (window.navigator.userAgent.indexOf('Safari') !== -1) {
  // Web animations polyfill ~(50kB) - for Safari and Edge
  // About 50kb raw, so only use if you need it.
  // const x = import('web-animations-js')
}

console.log('\n %cMade with %c♥%c by Fabrique \n', 'font: 16px serif;', 'font: 13px serif; color: #f00;', 'font: 16px serif;')

// Show debug styles on L hotkey and grid on G hotkey when document is focussed.
if (WEBPACK_ENV.ENV === 'development') {
// if (window.location.href.indexOf('fabriquehq') !== -1 || window.location.href.indexOf('127.0.0.1') !== -1) {
  document.addEventListener('keydown', event => {
    if (event.target !== document.body) {
      return
    }

    if (event.key === 'l') {
      document.documentElement.classList.toggle('debug-layout')
    }

    document.cookie = `debug-layout=${document.documentElement.classList.contains('debug-layout')}; path=/;`
  })

  document.documentElement.classList.toggle('debug-layout', document.cookie.indexOf('debug-layout=true') !== -1)
}

// Global things FIXME: where to put these better
Array.from(document.querySelectorAll('[href="[link-to-public-profile]"]')).forEach(link => {
  link.href = window.CNV_APP?.isLoggedIn ? window.CNV_APP.publicProfileUrl : window.CNV_APP?.myProfileUrl
})

Array.from(document.querySelectorAll('[href="[close-tab]"]')).forEach(link => {
  link.href = '#close'
  /* This only works for windows we opened ourselves, and is mainly meant for public profile pages (for the moment) */
  link.addEventListener('click', () => window.close())
})
